html,
body {
  font-size: 57.3333px;
}

.menu-rotate-open {
  animation: menu-open 250ms linear forwards;
}

.menu-rotate-close {
  animation: menu-close 250ms linear forwards;
}

@keyframes menu-open {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-180deg);
  }
}

@keyframes menu-close {
  from {
    transform: rotate(-180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.deposit-text-animation {
  animation: deposit-blink 1.5s ease 0s infinite normal none running;
}

@keyframes deposit-blink {
  0% {
    -webkit-transform: scale(0.74);
    transform: scale(0.74);
  }
  25% {
    -webkit-transform: scale(0.83);
    transform: scale(0.83);
  }
  50% {
    -webkit-transform: scale(0.74);
    transform: scale(0.74);
  }
  75% {
    -webkit-transform: scale(0.83);
    transform: scale(0.83);
  }
  100% {
    -webkit-transform: scale(0.74);
    transform: scale(0.74);
  }
}

.share-button {
  animation: share-rotate 6s linear infinite;
}

@keyframes share-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
  100% {
    -webkit-transform: rotate(2turn);
    transform: rotate(2turn);
  }
}

.container-no-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.container-no-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.alert-pulse {
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.marquee {
  height: 38px;
  overflow: hidden;
  position: relative;
}

.marquee p {
  position: absolute;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 38px;
  text-align: center;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -moz-animation: scroll-left 2s linear infinite;
  -webkit-animation: scroll-left 2s linear infinite;
  animation: scroll-left 20s linear infinite;
}

@-moz-keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
  }
}

@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@media only screen and (max-width: 450px) {
  .header-logo {
    height: 7vw !important;
  }
}

.social-con > div {
  width: 218px;
}

.social-con button {
  margin: 0 !important;
  border-radius: 20px !important;
  padding: 9px 9px 9px 15px !important;
  height: auto !important;
  font-size: 16px !important;
  width: 218px !important;
}

.MuiDialogActions-root .MuiButton-root {
  color: #333 !important;
}

.telegram-btn {
  visibility: hidden;
  max-width: 0;
  margin-left: 0px !important;
}

.rainbow-box::before {
  margin: -5px;
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 5px;
  padding: 7px;
  background: linear-gradient(
    135deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 10%,
    rgba(208, 222, 33, 1) 20%,
    rgba(79, 220, 74, 1) 30%,
    rgba(63, 218, 216, 1) 40%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 60%,
    rgba(95, 21, 242, 1) 70%,
    rgba(186, 12, 248, 1) 80%,
    rgba(251, 7, 217, 1) 90%,
    rgba(255, 0, 0, 1) 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.rhap_main-controls {
  flex: 1 1 auto;
}

.rhap_stacked .rhap_controls-section {
  margin-top: 0 !important;
  flex: unset !important;
  justify-content: center !important;
}

.rhap_additional-controls {
  flex: unset !important;
}

.rhap_play-pause-button,
.rhap_skip-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.rhap_main-controls-button {
  font-size: unset !important;
  flex: 1;
}

.rhap_container {
  background-color: green !important;
  border-radius: 10px !important;
  padding: 0px 15px !important;
}

.rhap_volume-controls {
  display: none !important;
}

.rhap_progress-section {
  display: none !important;
}

.page-maxwidth {
  max-width: 992px !important;
  margin-left: auto;
  margin-right: auto;
}

.mobile-nav-backdrop {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  background: rgb(22, 28, 36);
  background: -moz-linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgba(22, 28, 36, 1) 100%);
  background: -webkit-linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgba(22, 28, 36, 1) 100%);
  background: linear-gradient(75deg, rgba(22, 28, 36, 0.48) 0%, rgba(22, 28, 36, 1) 100%);
  z-index: 1199;
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.adjust-bottom-space {
  bottom: 8% !important;
}

.on::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 0.5rem;
  height: 0.04rem;
  border-radius: 0.02rem;
  background-color: #2b3990;
}

.nav_left_nologin {
  background: url(../assets/visitor/login-register-bg.png);
  flex-shrink: 0;
  width: 2.5rem !important;
  /* width: 100% !important; */
  height: 0.86rem;
  line-height: 1;
  font-size: 0.24rem;
  font-weight: 600;
  color: #fff;
  background: url(../assets/visitor/login-register-bg.png) no-repeat 50% / cover;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 0.2rem; */
}

.MuiPaper-root p,
.MuiPaper-root span,
.MuiPaper-root input,
.MuiPaper-root button,
.MuiPaper-root h4,
.MuiPaper-root h2,
.MuiPaper-root h1,
.MuiPaper-root h5,
.MuiPaper-root label,
.MuiPaper-root li,
.MuiPaper-root div {
  font-size: 0.25rem;
}

.MuiPaper-root svg {
  font-size: 0.3rem !important;
}

.SnackbarContent-root {
  font-size: 0.26rem !important;
  color: #333 !important;
}

.MuiDialog-paper {
  color: #333 !important;
  /* background-color: rgb(18, 18, 18) !important; */
  /* background-image: linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)) !important; */
}

.MuiDialog-paper .Mui-selected {
  background-color: #fff !important;
  color: #333 !important;
}

.MuiDialog-paper .MuiPickersDay-root {
  background-color: unset;
  color: #333 !important;
}

.lazy-load-image-background {
  color: inherit !important;
}

.pulse-shadow {
  animation: pulse 1.5s infinite;
  -webkit-animation: pulse 1.5s infinite;
}

/**
Angpao pulseating button animation
*/

@-webkit-keyframes pulse {
  0% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(90, 153, 212, 0);
  }
  100% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(90, 153, 212, 0);
  }
  100% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}

.angpao-content li {
  margin-left: 5%;
}

.custom-page:not(:empty) {
  color: #333;
}
